<template>
  <div class="d-none d-sm-none d-md-block d-lg-block d-xl-block" id="page-display-register">
    <div class="custom-bg-display">
      <b-container>
        <b-row>
          <b-col cols="12" sm="12" md="12" lg="12" xl="12">
            <div class="box-content">
              <div style="position: absolute;left:0;top:30px;">
                <h6 class="custom-title-color-white" style="font-size: 16px;font-weight: 600;cursor: pointer;" @click="pindahDisplayApm()"><CIcon name="cil-arrow-left" /> Kembali</h6>
              </div>
              <b-row>
                <b-col cols="12" sm="12" md="12" lg="8" xl="8" offset-lg="2" offset-xl="2">
                  <div style="width: 100%;display: flex;justify-content: center;">
                    <img :src="require(`../../assets/icons/kudus.png`)" style="height: 75px;margin-bottom: 30px;">
                  </div>
                  <h6 class="m-0 custom-title-color-white" style="font-size:36px;font-weight: 600;text-align: center;">{{ nama_puskesmas }}</h6>
                  <h6 class="mt-3 mb-3 custom-title-color-white" style="font-size: 18px;font-weight: 600;text-align: center;">Silahkan Masukkan Nomor BPJS / No. KTP</h6>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" sm="12" md="12" lg="8" xl="8" offset-lg="2" offset-xl="2">
                  <div style="padding: 0 60px;">
                    <input type="text" class="form-control form-control-lg" v-model="nomor_rm" id="" aria-describedby="" placeholder="Silahkan Masukkan Nomor BPJS / No. KTP" style="height: 60px;font-size:24px;text-align: center;">
                  </div>
                </b-col>
              </b-row>

              <b-row class="mt-3">
                <b-col cols="12" sm="12" md="12" lg="8" xl="8" offset-lg="2" offset-xl="2">
                  <div style="width: 100%;display: flex;justify-content: center;">
                    <div class="tombol-angka-lain" @click="nomor_rm = nomor_rm.slice(0,-1)">
                      <h6 style="color:#fff;"><CIcon name="cil-arrow-left" /></h6>
                    </div>

                    <div class="tombol-angka" @click="nomor_rm += '1'">
                      <h6 class="m-0">1</h6>
                    </div>

                    <div class="tombol-angka" @click="nomor_rm += '2'">
                      <h6 class="m-0">2</h6>
                    </div>

                    <div class="tombol-angka" @click="nomor_rm += '3'">
                      <h6 class="m-0">3</h6>
                    </div>

                    <div class="tombol-angka" @click="nomor_rm += '4'">
                      <h6 class="m-0">4</h6>
                    </div>

                    <div class="tombol-angka" @click="nomor_rm += '5'">
                      <h6 class="m-0">5</h6>
                    </div>

                    <div class="tombol-angka-lain"  @click="cekRM()">
                      <span style="color:#fff;"><CIcon name="cil-search" /></span>
                    </div>
                  </div>
                </b-col>
                <b-col cols="12" sm="12" md="12" lg="8" xl="8" offset-lg="2" offset-xl="2">
                  <div style="width:100%;display:flex;justify-content: center;">
                    <div class="tombol-angka" @click="nomor_rm += '6'">
                      <h6 class="m-0">6</h6>
                    </div>

                    <div class="tombol-angka" @click="nomor_rm += '7'">
                      <h6 class="m-0">7</h6>
                    </div>

                    <div class="tombol-angka" @click="nomor_rm += '8'">
                      <h6 class="m-0">8</h6>
                    </div>

                    <div class="tombol-angka" @click="nomor_rm += '9'">
                      <h6 class="m-0">9</h6>
                    </div>

                    <div class="tombol-angka" @click="nomor_rm += '0'">
                      <h6 class="m-0">0</h6>
                    </div>
                    
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-container>
      
      <div class="myfooter">
        <h6 style="color: #fff;font-size: 12px;text-align: center;" class="m-0">Powered by CV. Rapier Technology</h6>
      </div>
    </div>
    <b-modal id="modal-cari" size="lg" centered title="Data Pasien">
      <b-table-simple borderless>
        <b-tbody>
          <b-tr>
            <b-td style="width: 240px;">No. RM</b-td>
            <b-td style="width: 15px;">:</b-td>
            <b-td>{{ pasien.no_rm }}</b-td>
          </b-tr>

          <b-tr>
            <b-td>NIK</b-td>
            <b-td>:</b-td>
            <b-td>{{ pasien.nik }}</b-td>
          </b-tr>

          <b-tr>
            <b-td>Nama</b-td>
            <b-td>:</b-td>
            <b-td>{{ pasien.nama_lengkap }}</b-td>
          </b-tr>

          <b-tr>
            <b-td>Jenis Kelamin</b-td>
            <b-td>:</b-td>
            <b-td>{{ pasien.jenis_kelamin }}</b-td>
          </b-tr>

          <b-tr>
            <b-td>Tanggal Lahir</b-td>
            <b-td>:</b-td>
            <b-td>{{ $moment(pasien.tanggal_lahir).format("DD-MM-YYYY") }}</b-td>
          </b-tr>

          <b-tr>
            <b-td>Umur</b-td>
            <b-td>:</b-td>
            <b-td>{{ pasien.umur }}</b-td>
          </b-tr>

          <b-tr>
            <b-td>Alamat Pasien</b-td>
            <b-td>:</b-td>
            <b-td>{{ pasien.alamat_sekarang }}</b-td>
          </b-tr>

          <b-tr>
            <b-td>Nama Penanggung Jawab</b-td>
            <b-td>:</b-td>
            <b-td>{{ pasien.nama_penjamin }}</b-td>
          </b-tr>

          <b-tr>
            <b-td>No. Telp Penanggung Jawab</b-td>
            <b-td>:</b-td>
            <b-td>{{ pasien.no_telp_penjamin }}</b-td>
          </b-tr>

          <b-tr>
            <b-td>Hub. Penanggung Jawab</b-td>
            <b-td>:</b-td>
            <b-td>{{ pasien.hubungan_penjamin }}</b-td>
          </b-tr>

          <b-tr>
            <b-td>Penjamin</b-td>
            <b-td>:</b-td>
            <b-td>BPJS</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <template #modal-footer="{ ok, cancel }">
        <div class="w-100">
          <p class="float-left" style="font-size: 16px;color:#202020">Lanjutkan Pendaftaran ?</p>
          <!-- Emulate built in modal footer ok and cancel button actions -->
          <b-button variant="default" @click="cancel()" class="float-right">
            Tidak
          </b-button>

          <b-button variant="success" @click="pindahPilihPoli()" class="float-right mr-2">
            Ya
          </b-button>
         
          
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
// import axios from "axios";
// import { ipBackend } from "@/ipBackend";
import moment from "moment"
// import _ from "lodash"
moment.locale("ID")
export default {
  components: {
  //   antrianLoket,
  },
  name: "register_lama_umum",
  data() {
    return {
      loading: false,
      loadingTime: 0,
      maxLoadingTime: 4,
      poli: [],
      showing: false,
      msg: "",
      variant: "",
      show: false,
      nomor_rm: "",
      pasien: "",
      nama_puskesmas: "",
    };
  },
  computed:{
      logo() {
      return this.$store.state.logo;
    },
  },
  watch: {
      loading(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.clearLoadingTimeInterval()

          if (newValue) {
            this.$_loadingTimeInterval = setInterval(() => {
              this.loadingTime++
            }, 1000)
          }
        }
      },
      loadingTime(newValue, oldValue) {
        if (newValue !== oldValue) {
          
          if (newValue === this.maxLoadingTime) {
            this.loading = false
          }
        }
      }
    },
    
  mounted() {
    let id = this.$route.params.idapm
    this.nama_puskesmas = localStorage.getItem('nama_puskesmas')
    this.$store.commit('set_apm_id', `${id}`)
    this.getDatas();
    this.$socket.emit("joinRoom", `${id}`);
    // this.testing()
  },
  methods: {
    hideModal() {
      this.$refs['#modal-1'].hide()
    },
      clearLoadingTimeInterval() {
        console.log('itikiwir');
        clearInterval(this.$_loadingTimeInterval)
        this.$_loadingTimeInterval = null
      },
      startLoading() {
        console.log('masuk sini');
        this.loading = true
        this.loadingTime = 0
        
      },
    async cekRM(){
      let vm = this
      vm.$axios
        .post("/rm/details_rm_bpjs_by_nomor", {
          nomor: vm.nomor_rm,
          // puskesmas_id: 'a73ffa4d-79ef-4cff-8804-bfae35d391ee'
        })
        .then((res) => {
          console.log(res, 'ini res');
          if(res.data.status == 200 && res.data.message == 'sukses' && res.data.data.length != 0){
            console.log(res, 'ini respon nyaaa');
            vm.pasien = res.data.data[0]
            if(vm.pasien.nik == null && vm.pasien.tanggal_lahir == null){
              vm.$store.commit('set_alert', {showing: true, variant: 'danger', msg: "MAAF DATA PASIEN TIDAK LENGKAP! SILAHKAN AMBIL ANTRIAN LOKET UNTUK MELENGKAPI"})
            }else{
              vm.$store.commit('set_pasien', vm.pasien)
              vm.$store.commit('set_pasien_bpjs', res.data.bpjs[0])
              let expired = vm.pasien.tanggal_lahir ? moment(vm.pasien.tanggal_lahir) : moment();
              let now = moment();
              let hasil = moment.duration(now.diff(expired));
              vm.pasien.umur = `${hasil.years()} tahun ${hasil.months()} bulan ${hasil.days()} hari`;
              vm.$bvModal.show('modal-cari')
            }
            
          // console.log(res, 'berhasilllll');
          }else {
            vm.$store.commit('set_alert', {showing: true, variant: 'danger', msg: res.data.message})
            vm.showing = true;
            vm.variant = 'danger';
            vm.msg = "Maaf Nomor Rekam Medis tidak ditemukan";
            setTimeout(() => {
              vm.showing = false;
            }, 3000);
          }
          // console.log(res, 'ini ress');
        })
        .catch((err) => {
          console.log(err);
          vm.$store.commit('set_alert', {showing: true, variant: 'danger', msg: 'TERJADI KESALAHAN PADA SERVER / SERVER BPJS DOWN'})
        });
    },
    tutup(){
      let win = window.open("about:blank", "_self")
      win.close()
    },
    async getDatas() {
      let vm = this;
      let poli = await vm.$axios.post("/ms_poli/list", {
        // puskesmas_id: 'a73ffa4d-79ef-4cff-8804-bfae35d391ee'
      });
    //   console.log(poli, "iki lho hasil e");
      vm.poli = poli.data.data
      },

    pindahDisplayApm(){
      this.$router.push({ path: "/display_apm" });
    },
    pindahPilihPoli(){
      this.$router.push({ path: "/register_poli_bpjs" });
    },
    
  },
};
</script>
<style scoped>
#page-display-register ::placeholder{
  font-size: 22px;
}

#page-display-register .box-content{
  width: 100%;
  height: 94vh;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: column;
  position: relative;
  background-color: ;
}
#page-display-register .tombol-angka{
  width: 64px;
  height: 64px;
  border-radius: 16px;
  background: linear-gradient(109.67deg, #2ecc71 1.71%, #27ae60 100.75%),linear-gradient(0deg, #2ecc71, #27ae60);
  border: 4px solid #fff;
  margin:5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

#page-display-register .tombol-angka h6{
  font-size: 27px;
  color: #fff;
  font-weight: 600;
}
#page-display-register .tombol-angka-lain{
  width: 64px;
  height: 64px;
  border-radius: 16px;
  background-color: #2ecc71;
  border: 4px solid #fff;
  margin:5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
#page-display-register .tombol-angka-lain h6{
  font-size: 27px;
  color: #fff;
  font-weight: 600;
}

#modal-cari td{
  border:1px solid transparent !important;
  vertical-align:top !important;
}
tr, td{
    border: none !important;
  }

td{
  padding:0 !important;
  font-size: 16px;
  color: #202020;
  font-weight: 400;
  border:1px solid transparent
}

#page-display-register .myfooter{
  width: 100%;
  height:6vh;
  background-color: #00000080;
  display: flex;
  display: flex;
  justify-content: center;
  align-items: center;
}

i{
  font-size: 27px !important;
}

.c-icon:not(.c-icon-c-s):not(.c-icon-custom-size){
  width:27px !important;
  height:27px !important;
  font-weight: 600;
}
</style>
